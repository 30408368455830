import React from "react";

import {Link} from 'react-router-dom';




function Header() {
    return (
        <>
            <header className="header">


                <div className="header_content">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div
                                    className="main_nav_container_outer d-flex flex-row align-items-end justify-content-center trans_400">
                                    <nav className="main_nav">
                                        <div
                                            className="main_nav_container d-flex flex-row align-items-center justify-content-lg-start justify-content-center">
                                            <div>

                                                
                                                <ul className="d-flex flex-row align-items-start justify-content-end">
                                                    
                                                    <li><a href='https://mambeba.com.br.peqified.net'>Home</a></li>
                                                    <li><Link to='/sobre'>Sobre</Link></li>
                                                    <li><a href="#"></a></li>
                                                </ul>
                                            </div>
                                            <div className="logo_container text-center">
                                                <div className="trans_400"><a href="#"><img src="images/mambebalogo.png"
                                                    alt="" /></a></div>
                                            </div>
                                            <div>
                                                <ul className="d-flex flex-row align-items-start justify-content-start">
                                                    <li><a href="https://reservas.mambeba.com.br/">Entradas</a></li>
                                                    <li><a href="/contato">contato</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                    <div className="hamburger">
                                        <i className="fa fa-bars trans_200"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="header_bar">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div
                                        className="header_bar_content d-flex flex-row align-items-center justify-content-start">
                                        <div className="header_links">
                                            <ul className="d-flex flex-row align-items-start justify-content-start">
                                                <li><a href="https://reservas.mambeba.com.br/">Comprar Entradas</a></li>
                                                <li><a href="https://api.whatsapp.com/send/?phone=5537988423461&text&app_absent=0"><i className="fa fa-whatsapp"></i>&nbsp;Whatsapp</a></li>

                                            </ul>
                                        </div>
                                        <div
                                            className="header_bar_right ml-auto d-flex flex-row align-items-center justify-content-start">


                                            <div className="header_live">
                                                <a href="https://api.whatsapp.com/send/?phone=5537988423461&text&app_absent=0">
                                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                                        <div>Clique</div>
                                                        <div>Clique aqui e fale Conosco</div>
                                                    </div>
                                                </a>

                                            </div>


                                            <div className="user_area">
                                                <ul className="d-flex flex-row align-items-start justify-content-start">
                                                    <li><a href="#">Siga-nos:</a></li>
                                                    <li><a href="https://www.facebook.com/mambeba"><i className="fa fa-facebook"></i>&nbsp;&nbsp;</a></li>
                                                    <li><a href="https://www.instagram.com/mambeba.restaurante/"><i className="fa fa-instagram"></i>&nbsp;&nbsp;</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </header>

            <div className="menu">
                <div className="menu_background">
                    <div className="menu_container d-flex flex-column align-items-end justify-content-start">
                        <div className="menu_close">Fechar</div>
                        <div className="menu_user_area">
                            <ul className="d-flex flex-row align-items-start justify-content-end">
                            <li><a href="https://www.facebook.com/mambeba"><i className="fa fa-facebook"></i>&nbsp;&nbsp;</a></li>
                            <li><a href="https://www.instagram.com/mambeba.restaurante/">&nbsp;&nbsp;<i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                        <nav className="menu_nav">
                            <ul className="text-right">
                            <li><a href='https://mambeba.com.br.peqified.net'>Home</a></li>
                                                    <li><Link to='/sobre'>Sobre</Link></li>
                                <li><a href="https://reservas.mambeba.com.br/">reservas</a></li>
                                <li><a href="/contato">contato</a></li>
                            </ul>
                        </nav>
                        <div className="menu_links">
                            <ul className="d-flex flex-row align-items-start justify-content-start">
                                <li><a href="https://api.whatsapp.com/send/?phone=5537988423461&text&app_absent=0"><i className="fa fa-whatsapp"></i>&nbsp;Whatsapp</a></li>
                                <li><a href="https://api.whatsapp.com/send/?phone=5537988423461&text&app_absent=0">Fale Conosco</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </>

    )
}
export default Header;