import React from "react";
import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import Praia from "../promocao/praia.jpg";
import Hotel1 from "../promocao/hotel1.jpg";
import Hotel2 from "../promocao/hotel2.jpg";
import Fundo1 from "../sobre/fundo_sobre.png";
import Helmet from 'react-helmet';


// import "./about_responsive.css";
// import "./about.css";



function Sobre() {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="styles/about.css" />
                <link rel="stylesheet" type="text/css" href="styles/about_responsive.css" />
                <script src="js/about.js"></script>
            </Helmet>
            <Header />
            <div class="home2 d-flex flex-column align-items-start justify-content-end">
                <div class="parallax_background parallax-window" data-parallax="scroll" data-image-src={Fundo1} data-speed="0.8"></div>
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="home_text2">Nunca foi tão gostoso <span>Viajar!</span></div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="about">
                <div class="container">
                    <div class="row">


                        {/* <div class="col-xl-6 order-xl-1 order-2">
                            <div class="about_image">
                                <div class="background_image" ></div>
                                <img src={Fundo2} alt="" />
                            </div>
                        </div> */}


                        <div class="col-12 text-center">
                            <div class="about_content">
                                <div class="">
                                    <div class="section_title_container">
                                        <div class="section_title"><h1>Aqui tudo é diversão, inclusive uma viagem incrível para você e um acompanhante, com tudo pago.</h1></div>
                                    </div>
                                    <div class="about_title">A cada passaporte o cliente ganha um cupom para concorrer a viagem. Os cupons deverão ser depositados em urna presente no Mambeba.</div>
                                    {/* <div class="about_subtitle">Adquira seu ingresso</div> */}
                                    {/* <div class="about_text">
                                        <p>Aqui tudo é diversão, inclusive uma viagem incrível para você e um acompanhante, com tudo pago.</p>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
            <div class="team">
		<div class="container">
			<div class="row">
				<div class="col">
					<div class="section_title_container text-center">
						<div class="section_title"><h1>Conheça a Promoção</h1></div>
						<div class="section_subtitle">2023</div>
					</div>
				</div>
			</div>
			<div class="row team_large_row">
				
				
				<div class="col-lg-4">
					<div class="team_large">
						<div class="team_large_image"><img src={Hotel1} alt=""/></div>
						<div class="team_large_content text-center">
							<div class="team_large_name"><a href="#">Destino</a></div>
							<div class="team_large_title">HOTEL TROPICALIS PRAIA PONTA VERDE
MACEIÓ (AL)</div>
							{/* <div class="team_large_text">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan dolor id enim lacinia, sed feugiat ex suscipit.</p>
							</div> */}
							{/* <div class="team_large_history"><span>Past teams: </span><a href="#">Panthers</a>, <a href="#">The Cougars</a></div> */}
						</div>
					</div>
				</div>

				
				<div class="col-lg-4">
					<div class="team_large">
						<div class="team_large_image"><img src={Praia} alt=""/></div>
						<div class="team_large_content text-center">
							<div class="team_large_name"><a href="#">Perríodo da Campanha</a></div>
							<div class="team_large_title">30/07/2023 a 29/10/2023</div>
							{/* <div class="team_large_text">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan dolor id enim lacinia, sed feugiat ex suscipit.</p>
							</div>
							<div class="team_large_history"><span>Past teams: </span><a href="#">The Jaguars</a></div> */}
						</div>
					</div>
				</div>

				
				<div class="col-lg-4">
					<div class="team_large">
						<div class="team_large_image"><img src={Hotel2} alt=""/></div>
						<div class="team_large_content text-center">
							<div class="team_large_name"><a href="#">Data do sorteio</a></div>
							<div class="team_large_title">05/11/2023</div>
							{/* <div class="team_large_text">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan dolor id enim lacinia, sed feugiat ex suscipit.</p>
							</div>
							<div class="team_large_history"><span>Past teams: </span><a href="#">The Stars</a></div> */}
						</div>
					</div>
				</div>

			</div>
			
			<div class="row">
				<div class="col text-center">
					<div class="button team_button"><a href="https://mambeba.com.br.peqified.net/regulamento.pdf">Conheça o regulamento</a></div>
				</div>
			</div>
		</div>
	</div>
            
            <Footer />
        </>
    )
}
export default Sobre;